.header {
  display: grid;
  width: 100%;
  grid-template-columns: 250px 7fr 1fr;
  padding: 1.5% 1%;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  justify-items: center;
  align-items: center;
}

.logoSpace {
  grid-column: 1/2;
}

.navItemsSpace {
  grid-column: 2/3;
}

.buttonSpace {
  grid-column: 3/4;
  justify-self: end;
}

.currentLocation {
  font-weight: bold;
  color: grey;
}

.currentLocation:hover {
  font-weight: bold;
  cursor: default;
  color: grey;
}
