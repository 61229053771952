body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  align-items: center;
  align-content: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.primary-button {
  background: #30bced !important;
  border: #30bced !important ;
}

.secondary-button {
  background: #ff5e0a;
  border: #ff5e0a;
}

.background-color-gray {
  background: #eaeaea;
}

hr {
  background-color: rgb(0, 0, 0);
  height: 7px;
}

.table-color-gray {
  background: #e4e4e4;
}

.redirect {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

.line {
  background-color: rgb(0, 0, 0);
  height: 6px;
  opacity: 100;
}

input {
  outline: 0;
  border-width: 0 0 2px;
  border-color: rgb(168, 168, 168);
}

.abs-center-table {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 600px;
}

.border-red {
  border: 1px solid red;
}
