.component-span {
  display: block;
  resize: both;
}

.component-weight {
  padding: 0.15em 0 0 1em;
}

.component-input {
  width: 100%;
}

.score-span {
  resize: horizontal;
}

.score-input {
  margin-right: 10px;
  margin: 5px;
  width: 6ch;
}

.col-align-right {
  display: flex !important;
  justify-content: right !important;
}

.col-align-left {
  display: flex !important;
  justify-content: left !important;
}

.component-container {
  background: ghostwhite !important;
  margin-bottom: 30px !important;
  border: 1px solid lightgray !important;
  padding: 30px !important;
}

.error-message {
  color: red;
}
