.grid-container {
  display: grid;
  grid-template-columns: 75% 25%;
  grid-template-rows: auto auto;
  padding: 1% 1%;
}

.grid-button {
  grid-column: 2/3;
  grid-row: 1/2;
  justify-self: end;
  align-self: center;
}

.button-to-the-right {
  display: flex;
  align-self: flex-end;
  margin-top: 10px;
}
