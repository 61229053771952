.titleMargin {
  margin-top: 50px;
  display: flex;
  align-items: center;
}

.button-container {
  margin-left: auto;
  display: flex;
}

h1 {
  margin: 0;
}

h2 {
  font-size: 1.7em;
  font-weight: 500;
}

.button-3-Style {
  margin-left: auto;
  margin-right: 10px;
}

hr {
  background-color: rgb(0, 0, 0);
  height: 7px;
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: 1px solid;
  opacity: 0.25;
}

.centeredBoxData {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fixedTextValue {
  color: #7b7b7b;
}

.sizeInputField {
  width: 100px;
}

#table {
  display: table;
  border: 1px solid lightgray;
  background: ghostwhite;
}

.gridDimension {
  height: 100vh;
  width: "100%";
}

td {
  padding: 10px;
  margin: 10px;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: rgb(0, 0, 0);
  background-color: #fff;

  margin-bottom: 1%;
}

.nav-tabs {
  border-bottom: #f7efc6;
  font-size: 1.7em;
  font-weight: 500;

  --bs-nav-tabs-border-width: 5px;
  --bs-nav-tabs-border-color: #00000040;
  --bs-nav-tabs-border-radius: 0.375rem;
  --bs-nav-tabs-link-hover-border-color: #ffffff00 #ffffff00 #ffffff00;
  --bs-nav-tabs-link-active-bg: rgba(0, 0, 0, 0);
  --bs-nav-tabs-link-active-border: none;
  --bs-nav-tabs-link-active-border-color: #ffffff00 #ffffff00 #00000000;
  border-bottom: var(--bs-nav-tabs-border-width) solid
    var(--bs-nav-tabs-border-color);
  --bs-nav-tabs-link-active-bg: rgba(255, 20, 20, 0);
}

.nav {
  --bs-nav-link-color: gray;
}

.nav-tabs .nav-link:hover {
  color: rgb(74, 135, 152);
}

.error-message {
  margin-left: 10px;
  font-weight: bolder;
  border-color: #f33;
  color: #c33;
  font-size: 10pt;
}
