.title-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.borders-accordion {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
}
