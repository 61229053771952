@import "../../utils/_variables.css";

.margin-left-button {
  margin-left: 10px;
}

.edge-button {
  margin-top: 20px;
}

/* button-primary-orange */

.button-primary-orange {
  font-family: var(--bs-body-font-family);
  border: 2px solid var(--orange-1);
  border-radius: 8px;
  background: var(--orange-1);
  color: var(--white);
  padding: 0.375rem 0.75rem;
}

.button-primary-orange:hover {
  border: 2px solid var(--orange-hover);
  background: var(--orange-hover);
}

.button-primary-orange:disabled {
  border: 2px solid var(--orange-disabled);
  background: var(--orange-disabled);
}

/* button-primary-green */

.button-primary-green {
  font-family: var(--bs-body-font-family);
  border: 2px solid var(--green-1);
  border-radius: 8px;
  background: var(--green-1);
  color: var(--white);
  padding: 0.375rem 0.75rem;
}

.button-primary-green:hover {
  border: 2px solid var(--green-hover);
  background: var(--green-hover);
}

.button-primary-green:disabled {
  border: 2px solid var(--green-disabled);
  background: var(--green-disabled);
}

/* button-primary-light-blue */

.button-primary-light-blue {
  background-color: var(--blue-2);
  background: var(--blue-2);
  border: 2px solid var(--blue-2);
  border-radius: 8px;
  color: var(--white);
  padding: 0.375rem 0.75rem;
  --bs-btn-active-bg: var(--blue-2);
  --bs-btn-active-border-color: var(--blue-2);
}

.button-primary-light-blue:hover {
  border: 2px solid var(--blue-2-hover);
  background: var(--blue-2-hover);
}

.button-primary-light-blue:disabled {
  border: 2px solid var(--blue-2-disabled);
  background: var(--blue-2-disabled);
  color: var(--white);
}

/* button-secondary */

.button-secondary {
  border: 2px solid var(--grey-1);
  border-radius: 8px;
  background: var(--grey-1);
  color: var(--white);
  padding: 0.375rem 0.75rem;
}

.button-secondary:hover {
  border: 2px solid var(--grey-1-hover);
  background: var(--grey-1-hover);
}

.button-secondary:disabled {
  border: 2px solid var(--grey-1-disabled);
  background: var(--grey-1-disabled);
}

/* login-button */

.login-button {
  margin-top: 50px;
  border: 2px solid var(--purple-1);
  border-radius: 8px;
  background: var(--purple-1);
  color: var(--white);
  padding: 0.375rem 0.75rem;
}

.login-button:hover {
  border: 2px solid var(--purple-1-hover);
  background: var(--purple-1-hover);
}

.login-button:disabled {
  border: 2px solid var(--purple-1-disabled);
  background: var(--purple-1-disabled);
}

/* list-scenarios-button */

.list-scenarios-button {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  color: var(--black);
  --bs-btn-active-color: var(--blue-1);
}

.list-scenarios-button:hover {
  background: var(--transparent);
  color: var(--blue-1);
}

/* button-white */

.button-white {
  background-color: #fff;
  background: #fff;
  border: 2px solid #fff;
  border-radius: 8px;
  color: var(--blue-2);
  padding: 0.375rem 0.75rem;
  border: 1px solid var(--blue-2);
  --bs-btn-active-bg: #fff;
  --bs-btn-active-border-color: #fff;
}

.button-white:hover {
  border: 2px solid var(--blue-2);
  background: #fff;
}

.button-white:disabled {
  border: 2px solid #fff;
  background: #fff;
  color: var(--blue-2);
}
