.gridDimension {
  height: 100vh;
  width: "100%";
}

.container-bulk-actions {
  display: flex;
  justify-content: space-between;
  height: 60px;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}

.container-buttons {
  display: flex;
  width: 270px;
  justify-content: space-between;
}
