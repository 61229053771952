.layout-spaces {
  width: 100%;
  height: 100%;
  padding: 2% 0%;
}

.section-space {
  width: 100%;
  margin-bottom: 1%;
}

.section-buttons {
  display: flex;
  align-items: center;
}

.btn-action {
  flex: 0.8;
}
