.layout-spaces {
  width: 100%;
  height: 100%;
  padding: 2% 0%;
}

.section-space {
  width: 100%;
  margin-bottom: 1%;
}

.grid-container {
  display: grid;
  grid-template-columns: 75% 25%;
  grid-template-rows: auto auto;
  padding: 1% 1%;
}

.date-input-spacing {
  width: 48%;
}

.create-btn-margin {
  margin-left: 735px;
}

.discard-btn-margin {
  margin-left: 10px;
}

.btns-container-display {
  display: flex !important;
}
