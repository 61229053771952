.centered-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.full-width-element {
  width: 100%;
}

.error-message {
  color: red;
}

.full-width-div {
  position: relative;
  width: 100%;
}

.input-atom-width {
  width: 95%;
}
