.responsive-container {
  width: 750px;
  margin: 0 0;
}

@media (min-width: 899px) {
  .responsive-container {
    width: 50%;
  }
}

.contentPadding {
  padding: 2% 0%;
  width: 100%;
}

.centerText {
  text-align: center;
}
