.basic-tabs {
  border-bottom: 5px solid #c0c0c0;
}

.basic-tabs .MuiTabs-indicator {
  position: unset;
}

.tab-label {
  color: grey !important;
  font-size: 27px !important;
  text-transform: capitalize !important;
}

.tab-label:hover {
  color: #4a8798 !important;
}
