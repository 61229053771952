.grid-layout {
  display: grid !important;
  grid-template-columns: 1fr 0px 5fr;
}

.centered-spacing {
  padding: 100px 0 100px;
  text-align: center;
}

.centered-top-padding {
  padding-top: 10px;
  text-align: center;
}

.padding-small {
  padding: 15px 0 0 15px;
}

.spacing-bottom-px {
  margin-bottom: 20px;
}

.spacing-bottom-percentage {
  margin-bottom: 10%;
}

.paperContainer {
  padding: 1% 1%;
}
