.full-width-horizontal-form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
}

.column-div {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 85%;
}
