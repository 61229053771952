#confirmationModalRow {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
}

#confirmationModal {
  text-align: "justify";
  font-size: 24px;
}
