.title-container {
  display: grid;
  grid-template-columns: 20% 68% 10%;
  column-gap: 1%;
  width: 100%;
}

.first-space {
  grid-column: 1/2;
  justify-self: start;
  align-self: center;
}

.second-space {
  grid-column: 2/3;
  justify-self: start;
  align-self: center;
}

.third-space {
  grid-column: 3/4;
  justify-self: end;
  align-self: center;
}
