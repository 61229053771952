.titleContainer {
  display: grid;
  grid-template-columns: 20% 68% 10%;
  column-gap: 1%;
  width: 100%;
}

.firstSpace {
  grid-column: 1/2;
  justify-self: start;
  align-self: center;
}

.secondSpace {
  grid-column: 2/3;
  justify-self: start;
  align-self: center;
}

.thirdSpace {
  grid-column: 3/4;
  justify-self: end;
  align-self: center;
}

.flexContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
