.row-preference {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  padding: 10px;
  align-items: center;
  justify-items: start;
}

.text-value-preference {
  color: #7b7b7b;
  grid-column: 2/3;
  width: 65%;
  justify-self: end;
}

.button-preference {
  display: flex;
  padding: 10px;
}
