.create-button {
  text-align: center;
  margin-top: 15px;
  margin-bottom: 10px;
}

.add-subcomponent-button {
  padding: 20px 15px 15px 15px;
}

#componentForm {
  background: #eaeaea !important;
  padding: 20px 20px 20px 20px;
  margin-left: 5%;
  margin-right: 5%;
  border-radius: 10px;
}

#subComponentForm {
  margin-left: 5%;
  margin-right: 5%;
}

#subComponentRow {
  padding: 18px 10px 10px 10px;
  background-color: #d9d9d9;
  border-radius: 10px;
}

#subComponentTitle {
  border-bottom: 3px solid #000000;
  font-weight: "bold";
  padding-bottom: 5px;
  padding-top: 15px;
  width: 100%;
  text-align: "center";
}

.subcomponent-name {
  margin-left: 5%;
}
