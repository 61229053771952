.titleContainer {
  display: grid;
  grid-template-columns: 92% 8%;
  column-gap: 1%;
  width: 100%;
}

.thirdSpace {
  grid-column: 2;
  justify-self: center;
  align-self: center;
  margin-top: 27%;
}

.editedSaveAnswerButtons {
  grid-column: 2;
  justify-self: center;
  align-self: center;
  margin-top: 38%;
}

.editedCancelAnswerButtons {
  grid-column: 3;
  justify-self: center;
  align-self: center;
  margin-top: 38%;
}

.editedInputAnswer {
  display: grid;
  grid-template-columns: 86% 6% 6%;
  column-gap: 1%;
  width: 100%;
}
