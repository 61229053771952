.selected {
  background-color: #f4f2d5;
}

.dark_red {
  color: #852b2d;
  font-weight: 600;
}

.deactivated {
  color: #6b6969;
  font-weight: 600;
}

.centered_text {
  text-align: center;
}

.top_text {
  vertical-align: top;
}

.pointer_cursor {
  cursor: pointer;
}
