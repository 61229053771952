.cell-extra-left-padding {
  padding-left: 40px !important;
}

.header-extra-left-padding .MuiDataGrid-columnHeaderTitle {
  padding-left: 30px;
}

.switch-extra-right-padding {
  padding-right: 15px;
}

.container-element {
  max-width: 86rem;
  margin: 0 auto;
}

.custom-button {
  align-self: flex-end;
  width: 40px;
  height: 60px;
  margin-bottom: 50px;
  margin-top: 20px;
  background: #30bced;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12),
    0px 3px 5px rgba(0, 0, 0, 0.2);
  border-radius: 32px;
}

.see-results-color {
  background-color: #ff5e0a;
}

.export-color {
  color: blue;
}

.button-icon-right {
  .MuiButtonBase-root {
    visibility: visible;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
  }
  .MuiDataGrid-iconButtonContainer {
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.iconless-bold-column-header {
  .MuiDataGrid-iconSeparator {
    display: none;
  }
  .MuiDataGrid-columnHeaderTitle {
    font-weight: bold;
  }
}

.no-bottom-margin {
  .MuiTablePagination-selectLabel {
    margin-bottom: 0;
  }
  .MuiTablePagination-displayedRows {
    margin-bottom: 0;
  }
}
