.grid-container-round-question {
  display: grid;
  grid-template-columns: 80% 20%;
  grid-template-rows: auto auto auto;
  border-bottom: 1px solid #d9d9d9;
  grid-row-gap: 10px;
  grid-column-gap: 1%;
}

.button-placement {
  grid-row: "1/2";
  grid-column: "2/3";
  justify-self: end;
  align-self: center;
  margin-top: 6px;
}

.input-placement {
  grid-row: "2/3";
  grid-column: "1/2";
  justify-self: stretch;
  align-self: center;
}

.radio-type-placement {
  grid-row: "2/3";
  grid-column: "2/3";
  justify-self: end;
}

.grid-scoring-range-container {
  grid-row: "3/4";
  grid-column: "1/2";
}
