.third-column {
  grid-column: 3/4;
  grid-row: 1/2;
  justify-self: start;
  align-self: start;
}

.grid-form {
  display: grid;
  grid-template-columns: 20% 40% 30%;
  grid-column-gap: 5%;
  grid-template-rows: auto auto;
}

.grid-div {
  grid-column: 2/3;
  grid-row: 1/2;
}

.full-width-input-atom {
  width: 100%;
}
