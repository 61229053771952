:root {
  /*
    * Colors
    */
  --brand: #2995c0;
  --grey-1: #545d66;
  --grey-1-hover: #657078;
  --grey-1-disabled: #9fa9af;
  --grey-3: #dadada;
  --grey-4: #ededed;
  --orange-1: #fa6400;
  --orange-hover: #f79350;
  --orange-disabled: #cca589;
  --green-1: #3c933f;
  --green-hover: #6ac06d;
  --green-disabled: #a7cfa8;
  --blue-1: #0b5ed7;
  --blue-1-hover: #0d6efd;
  --blue-1-disabled: #98c0fc;
  --blue-2: #30bced;
  --blue-2-hover: #7ccfed;
  --blue-2-disabled: #739cac;
  --purple-1: #9c27b0;
  --purple-1-hover: #9e53ab;
  --purple-1-disabled: #7f6284;
  --color-type-body: var(--grey-1);
  --success: #2a7e2e;
  --atenttion: #2196f3;
  --wait: #fff8dd;
  --error: #d92525;
  --transparent: rgba(0, 0, 0, 0);
  --black: #000000;
  --white: #ffffff;

  /*
    * Fonts
    */
  --base-font-family: sans-serif;
  --headers-font-family: serif;
  --base-font-lineheight: 1.5em;
}
