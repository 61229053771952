.paperContainer {
  padding: 1% 1%;
}

.contentPadding {
  padding: 2% 0%;
  width: 100%;
}

.layoutSpaces {
  width: 100%;
  height: 100%;
  padding: 2% 0%;
}

.subSectionTitle {
  font-weight: bold;
}

.centerText {
  text-align: center;
}

.containerButtonOpenReceived {
  display: flex !important;
  justify-content: space-between !important;
}

.containerButtonOpen {
  display: flex !important;
  justify-content: flex-end !important;
}

.containerSpaceBottom {
  margin-bottom: 10px;
}

.containerSpaceTop {
  margin-top: 10px;
}

.avgText {
  margin-right: 50px;
}
